import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import {
  updateAutomaticSheetSizeMaterials,
  useActiveGroup,
  useActiveGroupParts,
  useCurrentGroupGrainDirection,
} from '@/api/materialsGroup';
import { useCutlistState } from '@/api/store';
import { selectActions } from '@/api/store';
import { Button } from '@/primitives/Button';
import { GrainHorizontal, GrainNone, Icon } from '@/primitives/Icons';

import styles from './PaintingSelect.module.css';

export const GrainDirectionSelect = () => {
  const activeGroup = useActiveGroup()!;
  const grainDirection = useCurrentGroupGrainDirection();
  const parts = useActiveGroupParts();
  const { t } = useTranslation();

  const { setPart } = useCutlistState(selectActions);

  if (!activeGroup) return null;

  const toggleGrainDirection = () => {
    parts.forEach((part) => {
      const updatedPart = { ...part };
      updatedPart['grainDirection'] =
        grainDirection === 'along' ? 'none' : 'along';
      setPart(updatedPart, activeGroup);
    });

    // auto update sheet size when part doesn't fit on current sheet
    if (activeGroup?.sheetSizeSelection === 'automatic')
      updateAutomaticSheetSizeMaterials(activeGroup);
  };

  return (
    <>
      <div className={styles.paintingBox}>
        <div className="flexAlign opposites">
          <div
            className={styles.paintingItem}
            role="button"
            tabIndex={0}
            onClick={toggleGrainDirection}
          >
            <Icon
              style={{
                visibility: 'hidden',
                width: 0,
              }}
              icon={<GrainHorizontal />}
              size={4}
            />
            <strong>
              {grainDirection === 'along'
                ? t('cutlist-form.grainDirection.along')
                : t('cutlist-form.grainDirection.none')}
            </strong>
          </div>
          <Button
            className={classNames(
              'smaller pill snug outline',
              activeGroup.paintColor ? 'delete' : null
            )}
            onClick={toggleGrainDirection}
          >
            <Icon
              icon={
                grainDirection === 'along' ? <GrainHorizontal /> : <GrainNone />
              }
            />
          </Button>
        </div>
      </div>
    </>
  );
};
