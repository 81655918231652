import { MaterialGroup } from '@cutr/constants/cutlist';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useCurrentGroupGrainDirection } from '@/api/materialsGroup';
import { selectActions, useCutlistState } from '@/api/store';
import { Modal } from '@/blocks/Modal';
import { Button } from '@/primitives/Button';
import { Dashboard, Icon, Layer, Menu } from '@/primitives/Icons';
import { useCurrentFeatures, useCurrentSource } from '@/theme';

import { CsvUploadHandler } from '../CsvCutlistUpload';
import { FreeTextModal } from '../PartTable/FreeTextModal';

export const MaterialGroupActions = ({ group }: { group: MaterialGroup }) => {
  const { t } = useTranslation();
  const { addPart, setPart } = useCutlistState(selectActions);
  const { csvUpload, hasPartStrips, grainDirectionLevel } =
    useCurrentFeatures();
  const [opener, setOpener] = React.useState<'freeTextIntake' | null>(null);
  const source = useCurrentSource();
  const isOpen = Boolean(opener);
  const grainDirection = useCurrentGroupGrainDirection();

  const sheetPart = useCutlistState((state) =>
    state.parts.find(
      (part) => part.groupId === group.id && part.partType === 'sheet'
    )
  );

  const addFullSheet = () => {
    window.analytics.track('Cutlist Full Sheet Added', { group });
    if (!sheetPart) {
      addPart(group, 'sheet');
      return;
    }
    const updatedSheetPart = { ...sheetPart };
    updatedSheetPart['quantity'] = (updatedSheetPart.quantity || 0) + 1;
    setPart(updatedSheetPart);
  };

  const addStrip = () => {
    window.analytics.track('Cutlist Strip Added', { group });
    const id = addPart(group, 'strip');
    // Focus on the first input of the new part
    window.requestAnimationFrame(() =>
      (
        document.querySelector(
          `#stripRow-${id} input:first-of-type`
        ) as HTMLInputElement
      )?.focus()
    );
  };

  const onAddPart = () => {
    window.analytics.track('Cutlist Part Added', { group });

    let id = '';
    if (grainDirectionLevel === 'group') {
      id = addPart(group, 'panel', grainDirection);
    } else {
      id = addPart(group);
    }

    // Focus on the first input of the new part
    window.requestAnimationFrame(() =>
      (
        document.querySelector(
          `#panelRow-${id} input:first-of-type`
        ) as HTMLInputElement
      )?.focus()
    );
  };

  const addStripTooltip =
    group.sheetSizeSelection === 'automatic'
      ? t('cutlist-form.errors.automaticSheetSizeWarning.addStrip')
      : '';
  const addStripDisabled =
    !group.core1 || group.sheetSizeSelection === 'automatic';

  const addFullSheetTooltip =
    group.sheetSizeSelection === 'automatic'
      ? t('cutlist-form.errors.automaticSheetSizeWarning.addFullSheet')
      : '';
  const addFullSheetDisabled =
    !group.core1 || group.sheetSizeSelection === 'automatic';

  return (
    <div className="flexAlign">
      {group.type === 'panels-and-strips' && (
        <Button
          name="add-part"
          disabled={!group.core1}
          onClick={onAddPart}
          variant="secondary"
          icon={<Icon icon={<Dashboard />} />}
        >
          {t('cutlist-form.cta.addPart')}
        </Button>
      )}

      {hasPartStrips && group.type === 'panels-and-strips' && (
        <Button
          name="add-strip"
          disabled={addStripDisabled}
          tooltip={addStripTooltip}
          onClick={() => addStrip()}
          variant="secondary"
          icon={<Icon icon={<Menu />} />}
        >
          {t('cutlist-form.cta.addStrip')}
        </Button>
      )}

      {group.type === 'sheets-only' && (
        <Button
          name="add-full-sheet"
          disabled={addFullSheetDisabled}
          tooltip={addFullSheetTooltip}
          onClick={() => addFullSheet()}
          variant="secondary"
          icon={<Icon icon={<Layer />} />}
        >
          {t('cutlist-form.cta.addFullSheet')}
        </Button>
      )}

      {csvUpload && group.core1 && <CsvUploadHandler />}
      {source === 'CUTR' && (
        <Button
          onClick={() => setOpener('freeTextIntake')}
          variant="secondary"
          className="tooltipLeft"
        >
          {t(`cutlist-form.freeTextIntake`)}
        </Button>
      )}

      {isOpen && (
        <Modal
          title={t(`upload-download.freeTextIntake.modalTitle`)}
          handleClose={() => setOpener(null)}
          isOpen={isOpen}
        >
          <div className="stack">
            {isOpen && <FreeTextModal onDone={() => setOpener(null)} />}
          </div>
        </Modal>
      )}
    </div>
  );
};
