import cn from 'classnames';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useActiveGroupGrainDirection,
  useEdgebandSelected,
} from '@/api/materialsGroup';
import { onFocusSelect } from '@/hooks';
import { Checkbox } from '@/primitives/Checkbox';
import { Dashboard, Icon } from '@/primitives/Icons';
import { Input } from '@/primitives/Input';
import { useCurrentFeatures } from '@/theme';
import { cleanNumberInput } from '@/utils/input';

import {
  Edgebanding,
  EdgeProfile,
  GrainButton,
} from '../PartTableCommon/Components';
import styles from '../PartTableCommon/styles.module.css';
import { TableRowActions } from '../PartTableCommon/TableRowActions';
import { TableRowProps } from '../PartTableCommon/types';

export const TableRowPanel = forwardRef<HTMLTableRowElement, TableRowProps>(
  function TableRowPanel(
    {
      part,
      hasError,
      onPartUpdate,
      onPartDelete,
      isFieldValid,
      validateNumericInput,
    }: TableRowProps,
    ref
  ) {
    const { t } = useTranslation();
    const hasGrainDirection = useActiveGroupGrainDirection();
    const { grainDirectionLevel } = useCurrentFeatures();

    const { edgebandingUI, hasEdgeProfiling, hasCreateLabels } =
      useCurrentFeatures();
    const isEdgebandSelected = useEdgebandSelected();
    const hasEdgebanding = isEdgebandSelected && edgebandingUI;

    return (
      <tr
        id={`panelRow-${part.id}`}
        className={hasError ? styles.errors : undefined}
        ref={ref}
      >
        <td>
          {
            <span className="flexAlign gap-xs">
              <Icon
                className={cn(styles.unflex, 'tooltip')}
                data-tooltip={t('cutlist-form.panel')}
                icon={<Dashboard />}
                color="var(--gray-7)"
              />
              <Input
                className="snug"
                defaultValue={part.label}
                placeholder={t('cutlist-form.field.partName.placeholder')}
                name={`label_${part.id}`}
                key={`label_${part.id}`}
                onFocus={onFocusSelect}
                onBlur={(e) => {
                  onPartUpdate('label', e.currentTarget.value);
                }}
              />
            </span>
          }
        </td>
        <td>
          <Input
            className={isFieldValid('quantity') ? 'snug' : 'snug invalid'}
            type="number"
            data-cy="part-quantity"
            value={part.quantity}
            name={`quantity_${part.id}`}
            key={`quantity_${part.id}`}
            onInput={(e) => {
              onPartUpdate(
                'quantity',
                validateNumericInput('quantity', e.currentTarget.value)
              );
            }}
          />
        </td>
        <td>
          <Input
            className="snug"
            data-cy="part-length"
            value={part.lengthMM}
            placeholder={t('cutlist-form.field.length.placeholder')}
            pattern="[0-9]+"
            name={`length_${part.id}`}
            key={`length_${part.id}`}
            onInput={(e) => {
              onPartUpdate('lengthMM', cleanNumberInput(e.currentTarget.value));
            }}
          />
        </td>
        <td>
          <Input
            className="snug"
            value={part.widthMM}
            data-cy="part-width"
            placeholder={t('cutlist-form.field.width.placeholder')}
            pattern="[0-9]+"
            name={`width_${part.id}`}
            key={`width_${part.id}`}
            onInput={(e) => {
              onPartUpdate('widthMM', cleanNumberInput(e.currentTarget.value));
            }}
          />
        </td>
        {hasEdgebanding && (
          <Edgebanding
            part={part}
            onUpdate={(edges) => {
              onPartUpdate('edgebanding', edges);
            }}
          />
        )}

        {hasEdgeProfiling && (
          <EdgeProfile
            part={part}
            onUpdate={(edges) => {
              onPartUpdate('edgeProfile', edges);
            }}
          />
        )}

        {hasGrainDirection && (
          <td className={styles.grain}>
            <GrainButton
              disabled={grainDirectionLevel === 'group'}
              direction={part.grainDirection}
              onUpdate={(value) => {
                onPartUpdate('grainDirection', value);
              }}
            />
          </td>
        )}

        {hasCreateLabels === 'part' && (
          <td className={styles.grain}>
            <Checkbox
              checked={Boolean(part.createLabel)}
              onChange={(e) => onPartUpdate('createLabel', e.target.checked)}
            />
          </td>
        )}
        <td style={{ '--spaceX': '0.1rem' } as React.CSSProperties}>
          <TableRowActions part={part} onPartDelete={onPartDelete} />
        </td>
      </tr>
    );
  }
);
